/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Textarea } from 'src/core/ds/textarea'
import { useGetTaskHistory } from 'src/modules/asideHistory/query'
import { useGetCurrentUser } from 'src/modules/currentUser/query'
import { api } from 'src/modules/StreamForm/api'
import { queryClient } from 'src/queries/reactQueryClient'
import { getCurrentUserKey, getTaskHistoryList } from 'src/queries/reactQueryKeys'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useStreamStore } from 'src/services/store/StreamStore'
import { ImageCardSkeleton } from 'src/skeletons/ImageCardSkeleton'
import { sendGAEvent } from 'src/utils/analytics'
import { DateFormats, formatDate } from 'src/utils/dates'
import { convertToWebp, copyToClipboard } from 'src/utils/strings'
import styles from './index.module.scss'

let channel

// const proOptions = [
//   { value: 1, label: 'Stable Diffusion XL v1.0' },
//   { value: 2, label: 'Dall-E 2' },
//   { value: 3, label: 'Midjourney (beta)' },
// ]


const ratioOptions = [
  { value: '1:1', label: '1:1' },
  { value: '3:4', label: '3:4' },
  { value: '4:3', label: '4:3' },
  { value: '5:4', label: '5:4' },
  { value: '4:5', label: '4:5' },
  { value: '9:16', label: '9:16' },
  { value: '16:9', label: '16:9' },
  { value: '21:9', label: '21:9' },
  { value: '9:21', label: '9:21' },
]

export function Text2Ai() {
  const [textInput, setTextInput] = useState('')
  // const [selectedModel, setSelectedModel] = useState(null)
  const [seed, setSeed] = useState(null)
  const streamStatus = useStreamStore((state) => state.status)
  const [ratio, setRatio] = useState(ratioOptions[0])
  // const { data: apiTokenDetails } = useGetApiToken()
  const [currentJob, setCurrentJob] = useState<any>(null)

  const updateStreamStatus = useStreamStore((state) => state.updateStatus)
  // const { data: jobDetails } = useGetJobPolling(currentJob)

  const { data: currentUser } = useGetCurrentUser()
  const {
    fetchNextPage,
    hasNextPage,

    isFetchingNextPage,
    isFetching,
    data: historyItems,
  } = useGetTaskHistory(currentJob && streamStatus === 'processing', currentJob)

  const items: any = historyItems?.pages?.flatMap((page) => page?.items ?? page)

  // Stop polling when data is no longer processing
  // if (streamStatus && items?.length && items?.filter((item) => item.status === 'completed').length === items?.length) {
  //   console.log('mda')
  //   // eslint-disable-next-line
  //   // @ts-ignore
  //   // queryClient.setQueryData(getTaskHistoryList('1'), (old) => ({ ...old, isFetching: false }))
  //   // queryClient.invalidateQueries({ queryKey: getTaskHistoryList('1') })
  //   updateStreamStatus('completed')
  //   // setCurrentJob(null)
  // }

  useEffect(() => {
    if (!isFetching && items && items.length > 0) {
      if (items?.filter((item) => item.status === 'completed').length === items?.length) {
        if (currentJob) {
          updateStreamStatus('completed')
          setCurrentJob(null)
        }
      }
    }
  }, [items, currentJob, isFetching])

  // Stop polling when data is no longer processing
  // if (jobDetails?.status === 'failed') {
  //   // eslint-disable-next-line
  //   // @ts-ignore
  //   queryClient.setQueryData(getJobDetailsKey(currentJob), (old) => ({ ...old, isFetching: false }))
  //   queryClient.invalidateQueries({ queryKey: getTaskHistoryList('1') })
  //   updateStreamStatus('completed')
  //   setCurrentJob(null)
  // }

  const createRequst = async () => {
    channel?.unbind()
    updateStreamStatus('processing')

    // if (!selectedModel) {
    //   pushNotification({
    //     type: 'error',
    //     title: 'Please, select the AI model.',
    //   })

    //   return
    // }

    try {
      setCurrentJob(null)

      // if (!selectedModel) return

      // const selectedAIConfig = configModels[selectedModel]
      const dataRaw = {
        // eslint-disable-next-line
        // @ts-ignore
        // ...selectedAIConfig,
        ratio: ratio.value ? ratio.value : ratio,
        seed,
        prompt: textInput,
      }
      const response: any = await api.verifyPromptBeforeRun(dataRaw)
      const [error, data] = response

      if (error) {
        updateStreamStatus('error')
      }

      setTextInput('')
      queryClient.invalidateQueries({ queryKey: getCurrentUserKey() })
      queryClient.invalidateQueries({ queryKey: getTaskHistoryList('1') })
      setCurrentJob(data.id.toString())
      sendGAEvent('image_generate')
    } catch (err) {
      console.log('err', err)

      if (err?.toString().includes('e_request_data_too_long')) {
        const errorString = 'Your input is too long. 200 is a Max.\n To increase the limit to 3000 – upgrade to PRO.'
        pushNotification({
          type: 'error',
          title: 'Error occured.',
          subtitle: errorString,
        })
      } else if (err?.toString().includes('e_job_concurrency_overflow')) {
        // const errorString = isPROAccount
        //   ? 'Your limits are: Text AI – 20 / 3 hours,\n Image AI – 10 / 3 hours'
        //   : 'Your limits are: Text AI – 10 / day,\n Image AI – 5 / day. To increase the limits, please use a PRO account.'

        pushNotification({
          type: 'error',
          title: `Exceeded the limit of concurrent requests. Please wait a bit or upgrade your plan.`,
          // subtitle: errorString,
        })
      } else if (err?.toString().includes('job_no_midj')) {
        // const errorString = isPROAccount
        //   ? 'Your limits are: Text AI – 20 / 3 hours,\n Image AI – 10 / 3 hours'
        //   : 'Your limits are: Text AI – 10 / day,\n Image AI – 5 / day. To increase the limits, please use a PRO account.'

        pushNotification({
          type: 'error',
          title: 'You do not have access to Midjourney. You need at least 5000 credits.',
          // subtitle: errorString,
        })
      } else if (err?.toString().includes('account_balance')) {
        // const errorString = isPROAccount
        //   ? 'Your limits are: Text AI – 20 / 3 hours,\n Image AI – 10 / 3 hours'
        //   : 'Your limits are: Text AI – 10 / day,\n Image AI – 5 / day. To increase the limits, please use a PRO account.'

        pushNotification({
          type: 'error',
          title: 'You do not have enought tokens for this generation.',
          // subtitle: errorString,
        })
      } else if (err?.toString().includes('_overflow')) {
        // const errorString = isPROAccount
        //   ? 'Your limits are: Text AI – 20 / 3 hours,\n Image AI – 10 / 3 hours'
        //   : 'Your limits are: Text AI – 10 / day,\n Image AI – 5 / day. To increase the limits, please use a PRO account.'

        pushNotification({
          type: 'error',
          title: 'You can run only one generation at time.',
          // subtitle: errorString,
        })
      } else {
        pushNotification({
          type: 'error',
          title: 'Internal error.',
          subtitle: 'Try again later or refresh the page.',
        })
      }

      updateStreamStatus('error')
    }
  }

  const onTextCopy = (copy) => {
    sendGAEvent('image_prompt_copy')
    copyToClipboard(
      copy,
      pushNotification({
        type: 'success',
        title: 'Copied to clipboard',
      }),
    )
  }

  // const items: any = historyItems?.pages?.flatMap((page) => page?.items ?? page)

  return (
    <div className={styles.dashboard}>
      <div className={styles.settings}>
        <h2>Settings</h2>
        {currentUser && (
          <div className={styles.upgradeBlock}>
            <p>Your balance:</p>
            <span>{currentUser?.balance}</span>
          </div>
        )}
        <div className={styles.form}>
          <div className={styles.formControl}>
            {/* eslint-disable-next-line */}
            <label htmlFor={'model'}>Your prompt</label>
            <Textarea value={textInput} onChange={(e) => setTextInput(e.target.value)} isRequired rows={3} />
          </div>

          <div className={styles.formControl}>
            {currentUser && (
              <Button
                // isLoading={streamStatus === 'processing'}
                disabled={!textInput}
                onClick={createRequst}
                label="Generate image"
                type="button"
              />
            )}

            {/* {streamStatus === 'completed' && (
              <div className={styles.regen}>
                <Button
                  variant={ButtonVariants.unstyled}
                  icon={IconNames.refresh}
                  type="button"
                  label="Regenerate"
                  iconColor="var(--color-text)"
                  onClick={createRequst}
                  // disabled={isLoadingUpdate}
                />
              </div>
            )} */}
          </div>

          <div className={styles.formControl}>
            {/* eslint-disable-next-line */}
            <label htmlFor={'ratio'}>Ratio</label>
            <Select
              className="basic-multi-select"
              classNamePrefix="select_custom"
              defaultValue={ratio}
              isSearchable
              // eslint-disable-next-line
              // @ts-ignore
              onChange={(value) => setRatio(value?.value)}
              name="ratio"
              // eslint-disable-next-line
              // @ts-ignore
              options={ratioOptions}
            />
          </div>
          <div className={styles.formControl}>
            {/* eslint-disable-next-line */}
            <label htmlFor={'seed'}>Seed (any integer)</label>
            {/* eslint-disable-next-line */}
            <Input value={seed || ''} onChange={(event) => setSeed(event.target.value)} />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <h1>Text to image</h1>
        <p className={styles.subtitle}>Generate stunning images using the latest AI models.</p>

        {currentUser && currentUser?.balance <= 0 && (
          <div className={styles.noBalance}>
            You have insufficient balance. If you want to test the use of API for your service, please write to us, we
            will provide you with additional credits for testing. Contact:{' '}
            <a href="mailto:hello@picogen.io">hello@picogen.io</a>
          </div>
        )}

        {currentUser && currentUser?.balance <= 6 && (
          <div className={styles.buy}>
            <Link to="/dashboard">
              <Button label="Buy credits" type="button" />
            </Link>
          </div>
        )}
        <div className={styles.list}>
          {!currentUser && (
            <div className={styles.signup}>
              To start generating images, please,
              <Link to="/"> login</Link>.
            </div>
          )}
          {/* {streamStatus === 'processing' && <ImageCardSkeleton />} */}

          {items?.map((item) => {
            const responseItems = (Array.isArray(item.result) ? item.result : [item.result] || []).filter(
              (item) => item !== undefined,
            )

            return responseItems?.map((img) =>
              item?.status === 'processing' || item?.status === 'created' ? (
                <ImageCardSkeleton />
              ) : (
                <div key={img} className={styles.listItem}>
                  <img loading="lazy" src={convertToWebp(img)} alt={item?.input?.data} />
                  <div className={styles.floatBox}>
                    <a rel="noreferrer" href={img} download target="_blank" className={styles.download}>
                      <Icon name={IconNames.download} />
                    </a>
                    <div className={styles.copy}>
                      <Button
                        label=""
                        icon={IconNames.copy}
                        type="button"
                        tooltipOnLeft
                        tooltip="Copy request"
                        variant={ButtonVariants.unstyled}
                        onClick={() => onTextCopy(item?.payload?.options?.prompt || item?.payload?.prompt || '')}
                      />
                    </div>
                    {item?.payload?.model && <span className={styles.model}>{item?.payload?.model}</span>}
                    <p className={styles.inputText}>{item?.payload?.options?.prompt || item?.payload?.prompt}</p>

                    <div className={styles.date}>
                      {/* eslint-disable-next-line */}
                      {/* @ts-ignore */}
                      {formatDate(
                        Number(item?.created_at * 1000 || Date.now()) as unknown as Date,
                        DateFormats.datePickerFullDate,
                      )}
                    </div>
                  </div>
                </div>
              ),
            )
          })}
        </div>

        <div className={styles.more}>
          {hasNextPage && (
            <div className={styles.tableActions}>
              <Button
                type="button"
                label="Load more"
                isLoading={isFetchingNextPage}
                disabled={!hasNextPage || isFetchingNextPage}
                onClick={fetchNextPage}
              />
            </div>
          )}
        </div>

        <div className={styles.preview}>
          <h2>Examples</h2>
          <div className={styles.list}>
            <div className={styles.listItem}>
              <img loading="lazy" src="https://cdn.picogen.io/image/240714/sample-1-thumb.webp" alt="preview" />
              <div className={styles.floatBox}>
                <a
                  rel="noreferrer"
                  href="https://cdn.picogen.io/image/240714/sample-1.webp"
                  download
                  target="_blank"
                  className={styles.download}
                >
                  <Icon name={IconNames.download} />
                </a>
                <div className={styles.copy}>
                  <Button
                    label=""
                    icon={IconNames.copy}
                    type="button"
                    tooltipOnLeft
                    tooltip="Copy request"
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      onTextCopy('extreme close up of the woman face, headshot, beautiful skin, extreme details')
                    }}
                  />
                </div>
                <p className={styles.inputText}>
                  extreme close up of the woman face, headshot, beautiful skin, extreme details
                </p>

                <div className={styles.date}>July, 14 2024</div>
              </div>
            </div>

            <div className={styles.listItem}>
              <img loading="lazy" src="https://cdn.picogen.io/image/240714/sample-2-thumb.webp" alt="preview" />
              <div className={styles.floatBox}>
                <a
                  rel="noreferrer"
                  href="https://cdn.picogen.io/image/240714/sample-2.webp"
                  download
                  target="_blank"
                  className={styles.download}
                >
                  <Icon name={IconNames.download} />
                </a>
                <div className={styles.copy}>
                  <Button
                    label=""
                    icon={IconNames.copy}
                    type="button"
                    tooltipOnLeft
                    tooltip="Copy request"
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      onTextCopy(
                        'clipart style, cute, playful scene, playful dog chasing a frisbee, with a bright, happy color palette, simple shapes, and thick, bold lines, hand-drawn digital illustration, highly detailed, perfect for children’s book, colorful, whimsical, Artstation HQ, digital art',
                      )
                    }}
                  />
                </div>
                <p className={styles.inputText}>
                  clipart style, cute, playful scene, playful dog chasing a frisbee, with a bright, happy color palette,
                  simple shapes, and thick, bold lines, hand-drawn digital illustration, highly detailed, perfect for
                  children’s book, colorful, whimsical, Artstation HQ, digital art
                </p>

                <div className={styles.date}>July, 14 2024</div>
              </div>
            </div>

            <div className={styles.listItem}>
              <img loading="lazy" src="https://cdn.picogen.io/image/240714/sample-3-thumb.webp" alt="preview" />
              <div className={styles.floatBox}>
                <a
                  rel="noreferrer"
                  href="https://cdn.picogen.io/image/240714/sample-3.webp"
                  download
                  target="_blank"
                  className={styles.download}
                >
                  <Icon name={IconNames.download} />
                </a>
                <div className={styles.copy}>
                  <Button
                    label=""
                    icon={IconNames.copy}
                    type="button"
                    tooltipOnLeft
                    tooltip="Copy request"
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      onTextCopy('A steampunk-inspired clockwork robot with brass gears and glowing blue eyes')
                    }}
                  />
                </div>
                <p className={styles.inputText}>
                  A steampunk-inspired clockwork robot with brass gears and glowing blue eyes
                </p>

                <div className={styles.date}>July, 14 2024</div>
              </div>
            </div>

            <div className={styles.listItem}>
              <img loading="lazy" src="https://cdn.picogen.io/image/240714/sample-4-thumb.webp" alt="preview" />
              <div className={styles.floatBox}>
                <a
                  rel="noreferrer"
                  href="https://cdn.picogen.io/image/240714/sample-4-thumb.webp"
                  download
                  target="_blank"
                  className={styles.download}
                >
                  <Icon name={IconNames.download} />
                </a>
                <div className={styles.copy}>
                  <Button
                    label=""
                    icon={IconNames.copy}
                    type="button"
                    tooltipOnLeft
                    tooltip="Copy request"
                    variant={ButtonVariants.unstyled}
                    onClick={() => {
                      onTextCopy(
                        'full body, cat dressed as a Viking, with weapon in his paws, battle coloring, glow hyper-detail, hyper-realism, cinematic',
                      )
                    }}
                  />
                </div>
                <p className={styles.inputText}>
                  full body, cat dressed as a Viking, with weapon in his paws, battle coloring, glow hyper-detail,
                  hyper-realism, cinematic{' '}
                </p>

                <div className={styles.date}>July, 14 2024</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
